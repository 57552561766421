import { Grid } from '@mui/material'
import React, { useEffect, useState, useContext, useLayoutEffect } from 'react'
import { MobileContext } from '../shared/context/MobileContext'
import useBrochureService from '../shared/services/BrochureService'
import BrochureCard from './BrochureCard'
import { useNavigate, useSearchParams } from "react-router-dom";
import { ASSET_TYPE, SESSION_STORAGE_CONSTS, STATUS_CODES, BROCHURE_TYPES, EVENT_CODES } from '../shared/constants/AppConsts'
import styled from '@emotion/styled'
import BrochureCardBroAd from './BrochureCardBroAd'
import LoadingOverlay from '../shared/components/LoadingOverlay'
import { CommonContext } from '../shared/context/CommonContext'
import BrochureCardCouponAd from './BrochureCardCouponAd'


const BrochureList = () => {
    const { getBrochures, addBorchureStats } = useBrochureService()
    const { selectedCategory, selectedMapArea, typeFilter } = useContext(MobileContext);
    const { loading } = useContext(CommonContext);
    const [brochures, setBrochures] = useState([])
    const [count, setCount] = useState(0)
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isScrollRestored, setIsScrollRestored] = useState(false);

    const handleClick = (brochureId, url = undefined, flipbookUrl = undefined) => {
        sessionStorage.setItem(SESSION_STORAGE_CONSTS.SCROLL_POSITION, window.pageYOffset);
        addBorchureStats(brochureId, EVENT_CODES.DETAIL_PAGE_VIEW);

        if (url) {
            window.open(url, "_blank", "noreferrer");
        } else if (flipbookUrl) {
            navigate(`/details/brochure-ad/${brochureId}`)
        } else {
            navigate(`/details/${brochureId}`);
        }
    }

    const truncateText = (text, length) => {
        return text ? text.substring(0, length) + " ..." : ""
    }

    let adCategory = [BROCHURE_TYPES.LANDING_PAGE_AD.code, BROCHURE_TYPES.PASS_THROUGH_AD.code]

    useEffect(() => {
        function init() {
            const res = window.location.pathname.split("/");
            if (res[1] == BROCHURE_TYPES.PASS_THROUGH_AD.name) {
                adCategory = [BROCHURE_TYPES.PASS_THROUGH_AD]
            }
            //setAdType(res[1])
            // setMapArea(ma)
            setCount(old => ++old)
        }
        init();
    }, [])

    /**
     * Map area QR codes are give and they are "{base_url}/?source=MAPAREA_QR_SCAN&mapArea={map_area_did}"
     */
    useEffect(() => {
        let brochureStatus = (searchParams.get("vendorId") || searchParams.get("id")) ? `${STATUS_CODES.PENDING_REVIEW},${STATUS_CODES.PUBLISHED}` : STATUS_CODES.PUBLISHED;
        async function getData() {
            const ma = searchParams.get("mapArea");
            const brochureTypes = searchParams.get("broTypes");
            console.log('brochureTypes', brochureTypes);
            
            
            if (typeFilter) {
                adCategory = [typeFilter]
            } else if (brochureTypes) {
                adCategory = [brochureTypes]
            } else {
                adCategory = [BROCHURE_TYPES.LANDING_PAGE_AD.code, BROCHURE_TYPES.PASS_THROUGH_AD.code, BROCHURE_TYPES.BROCHURE_AD.code, BROCHURE_TYPES.BLD_AD.code, BROCHURE_TYPES.COUPON_AD.code]
            }

            let mapArea = ma ? ma : selectedMapArea
            let data = await getBrochures('', selectedCategory, searchParams.get("vendorId"), searchParams.get("id"), brochureStatus, adCategory, mapArea, { action: 'brochureList' });
            data != '' && data.content.length > 0 ? setBrochures(data.content) : setBrochures([])
            setIsScrollRestored(false);
        }
        count > 0 && getData();
    }, [selectedMapArea, selectedCategory, count, typeFilter])

    useLayoutEffect(() => {
        if (!isScrollRestored) {
            const savedScrollPosition = sessionStorage.getItem(SESSION_STORAGE_CONSTS.SCROLL_POSITION);
            if (savedScrollPosition) {
                window.scrollTo(0, parseInt(savedScrollPosition));
                setIsScrollRestored(true);
            }
        }
    }, [isScrollRestored]);

    const getBrochureCards = brochures.map((brochure) => {
        let img = brochure.assets.filter(asset => asset.type === ASSET_TYPE.BROCHURE_THUMBNAIL_IMAGE)[0]?.url
        if (img == null) {
            brochure.assets?.sort((a, b) => a.did - b.did); // sort image assets by did asc
            img = brochure.assets.filter(asset => asset.type === ASSET_TYPE.BROCHURE_IMAGE)[0]?.url
        }

        const StandardBrochureCard = () => {
            return (
                <BrochureCard
                    onClick={() => handleClick(`${brochure.id}`, brochure.googleAdUrl)}
                    img={img}
                    title={brochure.title}
                    highlights={brochure.highlights ? brochure.highlights : truncateText(brochure.description, 400)}
                    categoryCode={brochure.category?.code}
                    categoryName={brochure.category?.name}
                    detailViewsCount={brochure.detailViewsCount || 0}
                    adIndex={brochure.adIndex || 0}
                    companyName={brochure.vendor.companyName}
                    BLDfilter={typeFilter === BROCHURE_TYPES.BLD_AD.code}
                />
            );
        }

        const BroAdBrochureCard = () => {
            return (
                <BrochureCardBroAd
                    onClick={() => handleClick(`${brochure.id}`, null, brochure.flipbookUrl)}
                    img={img}
                    title={brochure.title}
                    highlights={brochure.highlights ? truncateText(brochure.highlights, 400) : truncateText(brochure.description, 400)}
                    categoryCode={brochure.category?.code}
                    categoryName={brochure.category?.name}
                    detailViewsCount={brochure.detailViewsCount || 0}
                    adIndex={brochure.adIndex || 0}
                    companyName={brochure.vendor?.companyName}
                    contactPhone={brochure.contactPhone}
                    wwwUrl={brochure.wwwUrl}
                />
            );
        }

        const CouponBrochureCard = () => {
            return(
                <BrochureCardCouponAd
                    onClick={() => handleClick(`${brochure.id}`)}
                    img={img}
                    title={brochure.title}
                    highlights={brochure.highlights ? brochure.highlights : truncateText(brochure.description, 400)}
                    categoryCode={brochure.category?.code}
                    companyName={brochure.vendor?.companyName}
                    normalPrice={brochure.normalPrice}
                    discountedPrice={brochure.discountedPrice}
                    availableCoupons={brochure.numberOfCouponsPurchased ? (brochure.numberOfCoupons - brochure.numberOfCouponsPurchased) : 0}
                    numOfCoupons={brochure.numberOfCoupons}
                    validFrom={brochure.validFrom}
                    validTo={brochure.validTo}
                />
            )
        }

        return (
            <Grid item xs={12} sm={6} md={4} lg={3}>
                {
                    brochure.type === BROCHURE_TYPES.COUPON_AD.code ? 
                        <CouponBrochureCard /> 
                    : (brochure.type === BROCHURE_TYPES.BROCHURE_AD.code ? 
                        <BroAdBrochureCard /> 
                    : 
                        <StandardBrochureCard />)
                }
            </Grid>
        )
    });

    const GridA = styled('div')(({ theme }) => ({
        flex: 1,
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            paddingLeft: 64,
            paddingRight: 64,
        },
        minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - 32px)`,
    }));

    return (
        <GridA>
            <Grid sx={{ mt: 6 }} container>
                {loading && <LoadingOverlay />}
                {getBrochureCards}
            </Grid>
        </GridA>
    )
}

export default BrochureList


