
// export const REACT_APP_BASE_URL = "https://localoffer4u.com.au"
// export const REACT_APP_BASE_URL = "http://localhost:8080"
export const BASE_URL = process.env.REACT_APP_BASE_URL
export const HTTP_TIMEOUT = 60000

export const statesOfAU = [
    { id: '', name: 'Select State' },
    { id: 'NSW', name: 'New South Wales' },
    { id: 'NT', name: 'Northern Territory' },
    { id: 'QLD', name: 'Queensland' },
    { id: 'SA', name: 'South Australia' },
    { id: 'TAS', name: 'Tasmania' },
    { id: 'WA', name: 'Western Australia' },
    { id: 'VIC', name: 'Victoria' },
    { id: 'ACT', name: 'Australian Capital Territory' }
]

export const COUNTRY_CODE_AU = "AU"

export const ASSET_TYPE = {
    BROCHURE_IMAGE : "BROCHURE_IMAGE",
    BROCHURE_THUMBNAIL_IMAGE : "BROCHURE_THUMBNAIL_IMAGE",
    BROCHURE_PDF : "BROCHURE_PDF",
    BROCHURE_EXCEL : "BROCHURE_EXCEL",
    BROCHURE_WORD : "BROCHURE_WORD",
    BROCHURE_UNKNOWN : "BROCHURE_UNKNOWN"
 }

 export const SESSION_STORAGE_CONSTS = {
    SCROLL_POSITION : "scrollPosition",
    CURRENTLY_UPLOADING_BROCHURE_ID : "currentlyUploadingBrochureId",
 }

 export const STATUS_CODES = {
    PENDING_REVIEW : "PENDING_REVIEW",
    PUBLISHED : "PUBLISHED",
    ARCHIVED : "ARCHIVED"
 }

 export const EVENT_CODES = {
    DETAIL_PAGE_VIEW : "DETAIL_PAGE_VIEW",
 }

export const CONSTANTS = {
   DEFAULT_PASSWORD : "welcome@12",
}

export const USER_ROLES = {
   ADMINISTRATOR : "ADMINISTRATOR",
   VENDOR : "VENDOR"
}

export const BROCHURE_TYPES = {
   ATDW_AD : { code: 'ATDW_AD'}, // austrialian tourism data wareshouse ad
   PASS_THROUGH_AD : { code: 'PT_AD'}, // pass-through ad
   LANDING_PAGE_AD: { code: 'LP_AD'}, // landing page ad
   BROCHURE_AD: { code: 'BRO_AD'}, // brochure ad
   BLD_AD: { code: 'BLD_AD'},
   COUPON_AD: { code: 'CP_AD'}
}