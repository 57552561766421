import { useContext } from 'react';
import { BROCHURE_TYPES, COUNTRY_CODE_AU } from '../constants/AppConsts';
import useAxiosPrivate from './useAxiosPrivate';
import moment from 'moment';
import { CommonContext } from '../context/CommonContext';

const useBrochureService = () => {
    const { setLoading } = useContext(CommonContext);
    const { apiClient, apiClientPrivate } = useAxiosPrivate();

    async function getActiveBrochures() {
        return apiClient.get('/rest/brochure/public/active');
    }

    async function getBrochures(sortByEnum, categoryId, vendorId, brochureId, statuses, types, mapArea, info, isActive) {
        setLoading(true)

        categoryId = categoryId || ''
        sortByEnum = sortByEnum || 'VALID_FROM'
        vendorId = vendorId || ''
        brochureId = brochureId || ''
        statuses = statuses || ''
        types = types === undefined ? [BROCHURE_TYPES.LANDING_PAGE_AD.code, BROCHURE_TYPES.PASS_THROUGH_AD.code] : types
        mapArea = mapArea || ''
        isActive = isActive || false
        const action = info && info.action ? info.action : ''

        let response = await apiClient.get(`/rest/brochure/public/?sortBy=${sortByEnum}&category=${categoryId}&vendor=${vendorId}&id=${brochureId}&statuses=${statuses}&types=${types}&mapArea=${mapArea}&info=${action}&isActive=${isActive}`);
        
        // sort brochures by ad index
        if(response.status!=='204' && info && info.action === "brochureList"){
            let brochureDTOs = response.data.content
            brochureDTOs?.sort((a,b) => (a.adIndex > b.adIndex) ? 1 : ((b.adIndex > a.adIndex) ? -1 : 0))
            if (brochureDTOs) response.data.content=brochureDTOs
        }

        setLoading(false)
        return response.data;
        // &category=${categoryId}&vendor=${vendorId}
    }

    async function getBrochuresResponse(sortByEnum, categoryId, vendorId, brochureId, statuses, types, isActive) {
        categoryId = categoryId || ''
        sortByEnum = sortByEnum || 'VALID_FROM'
        vendorId = vendorId || ''
        brochureId = brochureId || ''
        statuses = statuses || ''
        types = types === undefined ? [BROCHURE_TYPES.LANDING_PAGE_AD.code, BROCHURE_TYPES.PASS_THROUGH_AD.code] : types
        isActive = isActive || false

        return await apiClient.get(`/rest/brochure/public/?sortBy=${sortByEnum}&category=${categoryId}&vendor=${vendorId}&id=${brochureId}&statuses=${statuses}&types=${types}&isActive=${isActive}`);
    }

    async function getBrochuresOrderedByDistance(category, refLatLng) {
        let response = apiClient.get(`/rest/brochure/public/withDistanceSorting?refLocWkt=${refLatLng}&category=${category}`);
        return (await response).data;
    }

    async function setBrochureStatus(brochureId, statusCode) {
        let response = await apiClientPrivate.patch(`/rest/brochure/administrator/${brochureId}/status?status=${statusCode}`);
        return response.data;
    }

    async function createBrochureWithAssets(brochureDetails, constValues) {

        // For BROCHURE_TYPES.LANDING_PAGE_AD & BROCHURE_TYPES.COUPON_AD
        let response = await apiClientPrivate.post(`/rest/brochure/administrator/createBrochureWithAssets`,
            {
                id: null,
                assets: brochureDetails.assets,
                type: constValues.brochureType,
                vendor: {
                    id: brochureDetails.vendorId,
                },
                brochureCategories: brochureDetails.categories.map(cat => {return{"code" : cat.subCode}}),
                title: brochureDetails.title,
                companyName: brochureDetails.companyName,
                personName: brochureDetails.personName,
                phoneNo: brochureDetails.phoneNo,
                email: brochureDetails.email,
                description: brochureDetails.description,
                contactName: brochureDetails.contactName,
                contactPhone: brochureDetails.phoneNo,
                contactEmail: brochureDetails.email,
                highlights: brochureDetails.highlights,
                validFrom: brochureDetails.validFrom !== '' ? moment(brochureDetails.validFrom, "YYYY-MM-DD").format("DD/MM/YYYY") : '',
                validTo: brochureDetails.validTo !== '' ? moment(brochureDetails.validTo, "YYYY-MM-DD").format("DD/MM/YYYY") : '',

                // For Coupon Ads
                normalPrice: brochureDetails.normalPrice,
                discountedPrice: brochureDetails.discountedPrice,
                numberOfCoupons: brochureDetails.numOfCoupons,

                mapArea: { did : brochureDetails.mapArea },
                adIndex: brochureDetails.mapAdIndex ,
                wwwUrl: brochureDetails.wwwUrl,
                facebookUrl: brochureDetails.facebookUrl,
                instagramUrl: brochureDetails.instagramUrl,
                linkedInUrl: brochureDetails.linkedInUrl,
                twitterUrl: brochureDetails.twitterUrl,
                flipbookUrl: brochureDetails.flipbookUrl,
                address: {
                    line1: brochureDetails.addressLine1,
                    line2: brochureDetails.addressLine2,
                    postcode: brochureDetails.postalCode,
                    city: brochureDetails.city,
                    state: brochureDetails.stateId,
                    locationUrl: brochureDetails.locationUrl,
                    countryCode: COUNTRY_CODE_AU
                },
                status: null,
                recordStatus: true
            }
        );
        return response.data;
    }

    async function updateBrochureWithAssets(brochureDetails, constValues) {
        
        // For BROCHURE_TYPES.LANDING_PAGE_AD & BROCHURE_TYPES.COUPON_AD
        let response = await apiClientPrivate.post(`/rest/brochure/vendor/updateBrochureWithAssets`,
            {
                id: brochureDetails.id,
                type: constValues.brochureType,
                assets: brochureDetails.assets,
                vendor: {
                    id: brochureDetails.vendorId,
                },
                brochureCategories: brochureDetails.categories.map(cat => {return{"code" : cat.subCode}}),
                title: brochureDetails.title,
                companyName: brochureDetails.companyName,
                personName: brochureDetails.personName,
                phoneNo: brochureDetails.phoneNo,
                email: brochureDetails.email,
                description: brochureDetails.description,
                contactName: brochureDetails.contactName,
                contactPhone: brochureDetails.phoneNo,
                contactEmail: brochureDetails.email,
                highlights: brochureDetails.highlights,
                validFrom: moment(brochureDetails.validFrom, "YYYY-MM-DD").isValid() ? moment(brochureDetails.validFrom, "YYYY-MM-DD").format("DD/MM/YYYY") : '',
                validTo: moment(brochureDetails.validTo, "YYYY-MM-DD").isValid() ? moment(brochureDetails.validTo, "YYYY-MM-DD").format("DD/MM/YYYY") : '',
                
                // For Coupon Ads
                normalPrice: brochureDetails.normalPrice,
                discountedPrice: brochureDetails.discountedPrice,
                numberOfCoupons: brochureDetails.numOfCoupons,

                mapArea: { did : brochureDetails.mapArea },
                adIndex: brochureDetails.mapAdIndex,
                wwwUrl: brochureDetails.wwwUrl,
                facebookUrl: brochureDetails.facebookUrl,
                instagramUrl: brochureDetails.instagramUrl,
                linkedInUrl: brochureDetails.linkedInUrl,
                twitterUrl: brochureDetails.twitterUrl,
                flipbookUrl: brochureDetails.flipbookUrl,
                address: {
                    line1: brochureDetails.addressLine1,
                    line2: brochureDetails.addressLine2,
                    postcode: brochureDetails.postalCode,
                    city: brochureDetails.city,
                    state: brochureDetails.stateId,
                    locationUrl: brochureDetails.locationUrl,
                    countryCode: COUNTRY_CODE_AU
                },
                status: null,
                recordStatus: true
            }
        );
        return response.data;
    }

    async function createBrochure(brochureDetails, constValues) {
        // For BROCHURE_TYPES.ATDW, BROCHURE_TYPES.PASS_THROUGH_AD
        let response = await apiClientPrivate.post(`/rest/brochure/administrator/create`,
            {
                id: null,
                type: constValues.brochureType,
                assets: brochureDetails.assets,
                vendor: {
                    id: brochureDetails.vendorId,
                },
                brochureCategories: brochureDetails.categories.map(cat => {return{"code" : cat.subCode}}),
                title: constValues.title !== null ? constValues.title : brochureDetails.title,
                companyName: brochureDetails.companyName,
                email: brochureDetails.email,
                personName: brochureDetails.personName,
                phoneNo: brochureDetails.phoneNo,
                googleAdUrl: brochureDetails.googleAdUrl,
                contactName: brochureDetails.contactName,
                contactPhone: brochureDetails.phoneNo,
                contactEmail: brochureDetails.email,
                description: null,
                highlights: brochureDetails.highlights,
                // validFrom: null,
                // validTo: null,
                validFrom: moment(brochureDetails.validFrom, "YYYY-MM-DD").isValid() ? moment(brochureDetails.validFrom, "YYYY-MM-DD").format("DD/MM/YYYY") : '',
                validTo: moment(brochureDetails.validTo, "YYYY-MM-DD").isValid() ? moment(brochureDetails.validTo, "YYYY-MM-DD").format("DD/MM/YYYY") : '',
                mapArea: { did : brochureDetails.mapArea },
                adIndex: brochureDetails.mapAdIndex,
                wwwUrl: brochureDetails.wwwUrl,
                facebookUrl: brochureDetails.facebookUrl,
                instagramUrl: brochureDetails.instagramUrl,
                address: {
                    line1: brochureDetails.addressLine1,
                    line2: brochureDetails.addressLine2,
                    postcode: brochureDetails.postalCode,
                    city: brochureDetails.city,
                    state: brochureDetails.stateId,
                    countryCode: COUNTRY_CODE_AU
                },
                status: null,
                recordStatus: true
            }
        );
        return response.data;
    }

    async function updateBrochure(brochureDetails, constValues) {
        let response = await apiClientPrivate.post(`/rest/brochure/administrator/update`,
            {
                id: brochureDetails.id,
                type: constValues.brochureType,
                assets: brochureDetails.assets,
                vendor: {
                    id: brochureDetails.vendorId,
                },
                // category: brochureDetails.categories.map(cat => {return{"code" : cat.subCode}}),
                brochureCategories: brochureDetails.categories.map(cat => {return{"code" : cat.subCode}}),
                personName: brochureDetails.personName,
                phoneNo: brochureDetails.phoneNo,
                googleAdUrl: brochureDetails.googleAdUrl,

                title: constValues.title !== null ? constValues.title : brochureDetails.title,
                companyName: brochureDetails.companyName,
                contactName: brochureDetails.contactName,
                contactPhone: brochureDetails.phoneNo,
                contactEmail: brochureDetails.email,
                highlights: brochureDetails.highlights,
                description: brochureDetails.description || null,
                //validFrom: null,
                //validTo: null,
                validFrom: moment(brochureDetails.validFrom, "YYYY-MM-DD").isValid() ? moment(brochureDetails.validFrom, "YYYY-MM-DD").format("DD/MM/YYYY") : '',
                validTo: moment(brochureDetails.validTo, "YYYY-MM-DD").isValid() ? moment(brochureDetails.validTo, "YYYY-MM-DD").format("DD/MM/YYYY") : '',
                mapArea: { did : brochureDetails.mapArea },
                adIndex: brochureDetails.mapAdIndex,
                wwwUrl: brochureDetails.wwwUrl,
                facebookUrl: brochureDetails.facebookUrl,
                instagramUrl: brochureDetails.instagramUrl,
                address: {
                    line1: brochureDetails.addressLine1,
                    line2: brochureDetails.addressLine2,
                    postcode: brochureDetails.postalCode,
                    city: brochureDetails.city,
                    state: brochureDetails.stateId,
                    countryCode: COUNTRY_CODE_AU
                },
                status: null,
                recordStatus: true
            }
        );
        return response.data;
    }

    async function addBorchureAsset(assetRequest) {
        let response = await apiClientPrivate.post(`/rest/brochure/administrator/addAsset`,
            {
                brochureId: assetRequest.brochureId,
                did: assetRequest.did,
                type: assetRequest.type,
                url: assetRequest.url,
                contentBase64Encoded: assetRequest.contentBase64Encoded
            }
        );

        return response.data;
    }

    async function addBorchureStats(brochureId, eventCode, userIdentification, deviceIdentification) {
        let response = await apiClient.post(`/rest/statistics/public`,
            {
                brochureId: brochureId,
                eventCode: eventCode,
                eventDateTime: new Date(),
                userIdentification: userIdentification,
                deviceIdentification: deviceIdentification
            }
        );
        return response;
    }

    async function purchaseCoupon(brochureId, username, email) {
        let response = await apiClient.post(`/rest/brochure/public/${brochureId}/purchase`,
            {
                name: username,
                email: email
            }
        );
        return response;
    }

    async function reviewBrochure(brochureId) {
        let response = await apiClientPrivate.patch(`/rest/brochure/administrator/${brochureId}/reviewal`,
            {}
        );

        return response.data;
    }

    async function notifyAdvertiserOnLandingPageCreated(brochureId, receipientEmail) {
        let response = await apiClientPrivate.patch(`/rest/brochure/administrator/${brochureId}/notifyAdvertiser/${receipientEmail}`,
            {}
        );

        return response.data;
    }

    async function sendActivityReport(brochureId) { // returns 1 on Success, -1 on Error
        let response = await apiClientPrivate.patch(`/rest/brochure/administrator/${brochureId}/sendActivityReport`, {});
        
        return response?.status == 200 ? 1 : -1
    }

    async function getMapAreas() {
        let response = await apiClient.get('rest/brochure/public/mapArea/all');
        return response.data;
    }

    async function getActiveMapAreas(activeTypes) {
        let response = await apiClient.get(`rest/brochure/public/mapArea/by-active-brochure-presence?brochureTypes=${activeTypes}`);
        return response.data;        
    }

    async function getStreetsIndexByArea(area) {
        let response = await apiClient.get('rest/brochure/public/streetsIndex?mapArea='+area);
        return response.data;
    }

    return {
        getActiveBrochures,
        getBrochures,
        getBrochuresResponse,
        getBrochuresOrderedByDistance,
        setBrochureStatus,
        createBrochureWithAssets,
        updateBrochureWithAssets,
        createBrochure,
        updateBrochure,
        purchaseCoupon,
        addBorchureAsset,
        addBorchureStats,
        reviewBrochure,
        notifyAdvertiserOnLandingPageCreated,
        sendActivityReport,
        getMapAreas,
        getActiveMapAreas,
        getStreetsIndexByArea
    }

}

export default useBrochureService

