import { Outlet } from 'react-router-dom'
import React, { useState } from 'react'
import TitleBarAboveSM from '../mobile/TitleBarAboveSM'
import { MobileContext } from '../shared/context/MobileContext'
import { ThemeProvider } from '@emotion/react'
import { mobileTheme } from '../themeMobile';
import { Box, useMediaQuery } from '@mui/material'
import TitleBarSM from '../mobile/TitleBarSM'
import Footer from '../shared/components/Footer'
import { CommonContext } from '../shared/context/CommonContext'
import { removeScrollPosition } from '../shared/util/utils'

const MobileLayout = (props) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedMapArea, setSelectedMapArea] = useState(null);
    const [typeFilter, setTypeFilter] = useState(null);
    const [loading, setLoading] = useState(false);

    const aboveSM = useMediaQuery(mobileTheme.breakpoints.up('md'));

    const changeTypeFilter = (type) => {
        removeScrollPosition();
        window.scrollTo(0, 0);
        setTypeFilter(type);
    }

    return (
        <ThemeProvider theme={mobileTheme}>
            <section className='mobile-background'>
                <CommonContext.Provider value={{ loading, setLoading }}>
                    <MobileContext.Provider value={{ selectedCategory, setSelectedCategory, selectedMapArea, setSelectedMapArea, typeFilter, changeTypeFilter }}>
                        {aboveSM ? <TitleBarAboveSM info={props.info} /> : <TitleBarSM info={props.info} />}

                        {/* <Box id="watermark">maps4u demo</Box> */}
                        {aboveSM ?
                            <Box sx={{ mt: '90px' }}>
                                <Outlet />
                                <Footer />
                            </Box> :
                            <Box>
                                <Outlet />
                                {/* <Footer /> */}
                            </Box>
                        }
                        {/* <BottomBar /> */}
                    </MobileContext.Provider>
                </CommonContext.Provider>
            </section>
        </ThemeProvider>
    )
}

export default MobileLayout
