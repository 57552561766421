import { AppBar, Toolbar, Stack, Button, Avatar, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import useAuth from '../hooks/useAuth';
import { USER_ROLES } from '../../shared/constants/AppConsts';
import useUserRole from '../hooks/useUserRole';
import Menu from '@mui/material/Menu';

const DesktopAppBar = () => {

    const navigate = useNavigate();
    // const { auth } = useAuth();
    const { gIsAdmin, gIsVendor, getUser } = useUserRole()

    const [user, setUser] = useState()
    const [role, setRole] = useState()
    const [brochureId, setBrochureId] = useState()

    const [adsAnchorEl, setAdsAnchorEl] = useState(null);
    const open = Boolean(adsAnchorEl);

    const menuOpen = (event) => {
        setAdsAnchorEl(event.currentTarget);
    };
    const menuClose = () => {
        setAdsAnchorEl(null);
    };

    useEffect(() => {
        async function getAuth() {
            const USER = getUser()
            const USER_ROLE = gIsVendor() ? USER_ROLES.VENDOR : USER_ROLES.ADMINISTRATOR

            setUser(USER)
            setRole(USER_ROLE)
            setBrochureId(localStorage.getItem('brochureId'))
        }
        getAuth();

    }, [])

    const logout = () => {
        // const vendorUrl = '/vendor/register/' + user + '/' + brochureId;
        const vendorUrl = '/login';

        let url = '/'
        url = role == USER_ROLES.VENDOR ? vendorUrl : url

        localStorage.setItem('accessToken', '');
        navigate(url)
    }

    const MenuItem = (props) => {

        const onClickFun = () => {
            setTimeout(() => { menuClose(); }, 150);
            const action = props.url ? navigate(props.url) : props.onClick();
        }

        return (
            [props.userRole].find(role => props.allowedRoles?.includes(role))
                ? <Button size='small' color={'inherit'} onClick={() => { onClickFun() }}>&nbsp; {props.name} &nbsp; </Button>
                : <></>
        );
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position='static' elevation={0} color={'grey'}>
                <Toolbar>
                    <Grid container justifyContent={'space-between'} alignItems='center'>
                        <Grid item >
                            <img src='/images/logo2.png' height={50} alt='' />
                        </Grid>
                        <Grid item >
                            <Stack direction='row' spacing={2}>
                                {/* <MenuItem name={'LANDING PAGE'} onClick={() => { }} userRole={role} allowedRoles={[USER_ROLES.VENDOR]}> </MenuItem> */}
                                {/* <MenuItem name={'VENDORS'} url={'/admin'} userRole={role} allowedRoles={ [USER_ROLES.ADMINISTRATOR]}> </MenuItem> */}

                                {[role].find(role => [USER_ROLES.ADMINISTRATOR]?.includes(role))
                                    ? <Button id='Ads-Button' name={"Ads"} color={'inherit'} onClick={menuOpen} aria-controls={open ? "Ads-Menu" : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>Ads</Button>
                                    : <></>}
                                <Menu id="Ads-Menu" anchorEl={adsAnchorEl} open={open} onClose={menuClose} MenuListProps={{ 'aria-labelledby': 'Ads-Button', }}>
                                    <Stack direction='column'>
                                        <Link to={'/admin/brochures'}><Button sx={{ justifyContent: "flex-start" }} userRole={role} allowedRoles={[USER_ROLES.ADMINISTRATOR]} onClick={menuClose}>ALL ADS</Button></Link>
                                        <Link to={'/admin/brochures/archived'}><Button sx={{ justifyContent: "flex-start" }} userRole={role} allowedRoles={[USER_ROLES.ADMINISTRATOR]} onClick={menuClose}>ARCHIVED ADS</Button></Link>
                                    </Stack>
                                </Menu>

                                <MenuItem name={'ADD NEW BROCHURE'} url={'/admin/manage-brochures'} userRole={role} allowedRoles={['USER_ROLES']}> </MenuItem>
                                <MenuItem name={'LANDING PAGE AD'} url={'/admin/brochure/manage/0'} userRole={role} allowedRoles={[USER_ROLES.ADMINISTRATOR]}> </MenuItem>
                                <MenuItem name={'PASS THROUGH AD'} url={'/admin/brochure/google-ad/manage/0'} userRole={role} allowedRoles={[USER_ROLES.ADMINISTRATOR]}> </MenuItem>
                                <MenuItem name={'BROCHURE AD'} url={'/admin/brochure/brochure-ad/manage/0'} userRole={role} allowedRoles={[USER_ROLES.ADMINISTRATOR]}> </MenuItem>
                                <MenuItem name={'COUPON AD'} url={'/admin/brochure/coupon-ad/manage/0'} userRole={role} allowedRoles={[USER_ROLES.ADMINISTRATOR]}> </MenuItem>
                                <MenuItem name={'LOGOUT'} onClick={logout} userRole={role} allowedRoles={[USER_ROLES.ADMINISTRATOR, USER_ROLES.VENDOR]}> </MenuItem>
                            </Stack>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    )
}

export default DesktopAppBar